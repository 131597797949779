import React from "react";
import { IconProps } from "../icon";

export const Rocket_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 11l-9.5 9.5M14.018 3.538a22.613 22.613 0 013.483 2.855 22.61 22.61 0 012.876 3.515M9.255 7.896L6.38 6.938a1 1 0 00-.962.185L2.56 9.541a1 1 0 00.3 1.701l2.708.998m6.113 6.112l.997 2.708a1 1 0 001.702.3l2.417-2.857a1 1 0 00.186-.962l-.959-2.875M19.348 2.27l-4.906.817a2.443 2.443 0 00-1.383.741L6.446 10.9a4.653 4.653 0 006.576 6.575l7.07-6.613c.391-.367.652-.853.74-1.382l.818-4.907a2 2 0 00-2.302-2.301z"
    />
  </svg>
);
