import React from "react";
import { IconProps } from "../icon";

export const Globe_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 2.458A9.996 9.996 0 0012 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-1.715-.432-3.33-1.193-4.74M17 5.75h.005M10.5 21.888v-2.203a.5.5 0 01.12-.325l2.486-2.9a.5.5 0 00-.131-.76l-2.857-1.632a.499.499 0 01-.186-.187L8.07 10.62a.5.5 0 00-.478-.25l-5.528.492M21 6c0 2.21-2 4-4 6-2-2-4-3.79-4-6a4 4 0 018 0zm-3.75-.25a.25.25 0 11-.5 0 .25.25 0 01.5 0z"
    />
  </svg>
);
