import React from "react";
import { IconProps } from "../icon";

export const MarkIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 12a8 8 0 01-8 8m8-8a8 8 0 00-8-8m8 8h2m-10 8a8 8 0 01-8-8m8 8v2M4 12a8 8 0 018-8m-8 8H2m10-8V2m3 10a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);
