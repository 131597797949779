import React from "react";
import { IconProps } from "../icon";

export const Globe_06Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.687 15.645l1.908-1.101a.5.5 0 01.342-.059l3.754.703a.5.5 0 00.592-.494l-.014-3.29a.5.5 0 01.068-.254l1.895-3.244a.5.5 0 00-.023-.54l-3.19-4.54M19 4.859C13.5 7.5 16.5 11 17.5 11.5c1.877.938 4.488 1 4.488 1 .008-.166.012-.332.012-.5 0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10c.168 0 .334-.004.5-.012m4.258-.048L13.59 13.59l8.349 3.167-3.702 1.48-1.48 3.702z"
    />
  </svg>
);
