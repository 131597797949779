import React from "react";
import { IconProps } from "../icon";

export const Flag_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.111 6.722h6.03c.447 0 .67 0 .801.094a.5.5 0 01.205.348c.019.16-.09.356-.307.747l-1.47 2.645c-.079.142-.118.213-.133.288a.499.499 0 000 .201c.015.075.054.146.133.288l1.47 2.645c.217.391.326.587.307.747a.5.5 0 01-.205.348c-.13.094-.354.094-.802.094h-7.596c-.56 0-.84 0-1.053-.11a1 1 0 01-.438-.436c-.109-.214-.109-.494-.109-1.054v-2.623M7.25 21.5L3.028 4.611m1.583 6.333h7.9c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437c.11-.213.11-.493.11-1.054V4.1c0-.56 0-.84-.11-1.054a1 1 0 00-.437-.437c-.214-.109-.494-.109-1.054-.109H4.55c-.698 0-1.048 0-1.286.145a1 1 0 00-.438.56c-.082.267.003.605.172 1.283l1.614 6.456z"
    />
  </svg>
);
